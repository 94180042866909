import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import api from '../../services/api-client';
import { toast } from 'react-toastify';

interface QuestionTag {
  id: number;
  name: string;
}

const QuestionTagManager: React.FC = () => {
  const [tags, setTags] = useState<QuestionTag[]>([]);
  const [newTag, setNewTag] = useState('');
  const [editTag, setEditTag] = useState<QuestionTag | null>(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = async () => {
    try {
      const response = await api.get('questionTag');
      setTags(response.data);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  const handleAddTag = async () => {
    try {
      await api.post('questionTag', { name: newTag });
      setNewTag('');
      fetchTags();
    } catch (error) {
      console.error('Error adding tag:', error);
    }
  };

  const handleEditTag = (tag: QuestionTag) => {
    setEditTag(tag);
    setOpenDialog(true);
  };

  const handleUpdateTag = async () => {
    if (editTag) {
      try {
        await api.put(`questionTag/${editTag.id}`, editTag);
        setOpenDialog(false);
        fetchTags();
      } catch (error) {
        console.error('Error updating tag:', error);
      }
    }
  };

  const handleDeleteTag = async (id: number) => {
    try {
      await api.delete(`questionTag/${id}`);
      fetchTags();
      toast.success('Tag deleted successfully');
    } catch (error: any) {
      if (error.response && error.response.status === 500 && error.response.data.message === "Tag is linked to questions. Cannot delete.") {
        toast.error('This tag is linked to questions and cannot be deleted.');
      } else {
        console.error('Error deleting tag:', error);
        toast.error('An error occurred while deleting the tag. Please try again.');
      }
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <TextField
          label="New Subject"
          value={newTag}
          onChange={(e) => setNewTag(e.target.value)}
          sx={{ mr: 2 }}
        />
        <Button variant="contained" onClick={handleAddTag}>
          Add Subject
        </Button>
      </Box>
      <List>
        {tags.map((tag) => (
          <ListItem
            key={tag.id}
            secondaryAction={
              <>
                <IconButton edge="end" aria-label="edit" onClick={() => handleEditTag(tag)}>
                  <Edit />
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteTag(tag.id)}>
                  <Delete />
                </IconButton>
              </>
            }
          >
            <ListItemText primary={tag.name} />
          </ListItem>
        ))}
      </List>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Edit Tag</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Tag Name"
            fullWidth
            value={editTag?.name || ''}
            onChange={(e) => setEditTag(editTag ? { ...editTag, name: e.target.value } : null)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleUpdateTag}>Update</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default QuestionTagManager;