import React, { useState } from "react";
import {
    AppBar, Button, Card, CardContent, Container, Dialog, DialogActions,
    Grid, Toolbar, Typography, Box, Paper
} from "@mui/material";
import LoginForm from "../Login";
import SignupForm from "../Signup";
import { useNavigate } from "react-router-dom";
import { doLogout, isUserLoggedIn } from "../../services/auth";
import { theme } from "../App/App";

export const LandingPage = () => {
    const [loginOpen, setLoginOpen] = useState(false);
    const [signupOpen, setSignupOpen] = useState(false);
    const navigate = useNavigate();

    const handleLoginClick = () => setLoginOpen(true);
    const handleClose = () => setLoginOpen(false);
    const handleSignupClick = () => setSignupOpen(true);
    const handleSignupClose = () => setSignupOpen(false);

    const placeholderImages = [
        "/ai.png",
        "/ai.png",
        "/ai.png",
    ];

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color="primary">
                <Toolbar>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        Sailing Manager Exam
                    </Typography>
                    {isUserLoggedIn() ? (
                        <>
                            <Button
                                color="inherit"
                                onClick={() => doLogout(() => navigate("/"))}
                            >
                                Logout
                            </Button>
                            <Button
                                color="inherit"
                                variant="outlined"
                                onClick={() => navigate("/user/home")}
                                sx={{ ml: 2 }}
                            >
                                Dashboard
                            </Button>
                            <Button
                                color="inherit"
                                variant="outlined"
                                onClick={() => navigate("/user/admin")}
                                sx={{ ml: 2 }}
                            >
                                Admin Panel
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button color="inherit" onClick={handleLoginClick}>
                                Login
                            </Button>
                            <Button
                                color="inherit"
                                variant="outlined"
                                sx={{ ml: 2 }}
                                onClick={handleSignupClick}
                            >
                                Sign Up
                            </Button>
                        </>
                    )}
                </Toolbar>
            </AppBar>

            <Container maxWidth="lg" sx={{ mt: 4 }}>
                <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
                    <Typography variant="h5" gutterBottom>
                        Using the Sailing Manager Exam App
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Welcome to the Sailing Manager Exam application. Here's how you can get started:
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        Student Mode:
                    </Typography>
                    <Typography variant="body1" component="div">
                        <ul>
                            <li>Sign up for an account or log in if you already have one.</li>
                            <li>Once logged in, you'll be directed to your dashboard.</li>
                            <li>From there, you can start new exams, review past attempts, and track your progress.</li>
                            <li>After completing an exam, you can view detailed feedback to improve your knowledge.</li>
                        </ul>
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        Admin Mode:
                    </Typography>
                    <Typography variant="body1" component="div">
                        <ul>
                            <li>Log in with your admin credentials.</li>
                            <li>Access the Admin Panel to manage exams, questions, and user accounts.</li>
                            <li>Create new exams, add or edit questions, and review student performances.</li>
                        </ul>
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        To get started, please log in or sign up using the buttons in the top right corner.
                    </Typography>
                </Paper>
            </Container>

            <footer style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText, padding: '1rem', marginTop: '2rem' }}>
                <Typography variant="body2" align="center">
                    © {new Date().getFullYear()} Sailing Manager Exam. All rights reserved.
                </Typography>
            </footer>

            {/* Login Dialog */}
            <Dialog open={loginOpen} onClose={handleClose}>
                <LoginForm />
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Signup Dialog */}
            <Dialog open={signupOpen} onClose={handleSignupClose}>
                <SignupForm />
                <DialogActions>
                    <Button onClick={handleSignupClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};