// stores/StoreProvider.tsx
import React, { useEffect, useState } from "react";
import { appStore } from "./AppStore";

export const storeContext = React.createContext(appStore);

type Props = {
  children: React.ReactNode;
};

export const StoreProvider: React.FC<Props> = ({ children }) => {
  const [loadingProgress, setLoadingProgress] = useState(0); // Tracks the progress
  const [isCompleted, setIsCompleted] = useState(false);
  // When the progress reaches 100%, hide the progress bar
  if (loadingProgress === 100 && isCompleted) {
    return (
      <storeContext.Provider value={appStore}>
        {children}
      </storeContext.Provider>
    );
  }

  return (
    <div>
      <storeContext.Provider value={appStore}>
        {children}
      </storeContext.Provider>
    </div>
  );
};

export const useGlobalStore = () => {
  const context = React.useContext(storeContext);
  if (!context) {
    throw new Error("useGlobalStore must be used within a StoreProvider");
  }
  return context;
};
