import React, { useState, useEffect } from 'react';
import {
  // ... other imports
  Feedback as FeedbackIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Typography,
  Grid,
  Divider,
} from '@mui/material';
import { Add, Delete, Edit, Save } from '@mui/icons-material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import api from '../../services/api-client';
import { toast } from 'react-toastify';

interface Question {
  id: number;
  question: string;
  modelAnswerMap: { [key: number]: string };
  tagIds: number[];
}

interface QuestionTag {
  id: number;
  name: string;
}

interface FeedbackDTO {
  id: number;
  questionId: number;
  feedback: string;
  submittedAnswerId: number;
}

const QuestionManager: React.FC = () => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [tags, setTags] = useState<QuestionTag[]>([]);
  const [newQuestion, setNewQuestion] = useState<Omit<Question, 'id'>>({
    question: '',
    modelAnswerMap: {},
    tagIds: []
  });
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState<number | null>(null);
  const [feedbacks, setFeedbacks] = useState<FeedbackDTO[]>([]);
  const [newFeedback, setNewFeedback] = useState<Omit<FeedbackDTO, 'id'>>({
    questionId: 0,
    feedback: '',
    submittedAnswerId: 0,
  });
  const [editingFeedbackId, setEditingFeedbackId] = useState<number | null>(null);
  const [editingQuestion, setEditingQuestion] = useState<Question | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchQuestions();
    fetchTags();
  }, []);

  const fetchQuestions = async () => {
    setLoading(true);
    try {
      const response = await api.get('question');
      setQuestions(response.data);
    } catch (error) {
      console.error('Error fetching questions:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTags = async () => {
    setLoading(true);
    try {
      const response = await api.get('questionTag');
      setTags(response.data);
    } catch (error) {
      console.error('Error fetching tags:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchFeedbacks = async (questionId: number) => {
    try {
      const response = await api.get(`feedback/question/${questionId}`);
      setFeedbacks(response.data);
    } catch (error) {
      console.error('Error fetching feedbacks:', error);
    }
  };

  const handleAddQuestion = async () => {
    setLoading(true);
    try {
      await api.post('question', newQuestion);
      setNewQuestion({ question: '', modelAnswerMap: {}, tagIds: [] });
      fetchQuestions();
      setOpenAddDialog(false);
    } catch (error) {
      console.error('Error adding question:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteQuestion = async (id: number) => {
    try {
      await api.delete(`question/${id}`);
      fetchQuestions();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleEditQuestion = (question: Question) => {
    setEditingQuestion(question);
    setOpenAddDialog(true);
  };

  const handleUpdateQuestion = async () => {
    if (editingQuestion) {
      setLoading(true);
      try {
        await api.put(`question/${editingQuestion.id}`, editingQuestion);
        fetchQuestions();
        setOpenAddDialog(false);
        setEditingQuestion(null);
      } catch (error) {
        console.error('Error updating question:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleAddFeedback = async () => {
    if (selectedQuestionId) {
      try {
        await api.post('feedback', { ...newFeedback, questionId: selectedQuestionId });
        setNewFeedback({ ...newFeedback, feedback: '' });
        fetchFeedbacks(selectedQuestionId);
      } catch (error) {
        console.error('Error adding feedback:', error);
      }
    }
  };

  const handleEditFeedback = (feedbackId: number) => {
    setEditingFeedbackId(feedbackId);
  };

  const handleUpdateFeedback = async (feedback: FeedbackDTO) => {
    try {
      await api.put(`feedback/${feedback.id}`, feedback);
      setEditingFeedbackId(null);
      fetchFeedbacks(feedback.questionId);
    } catch (error) {
      console.error('Error updating feedback:', error);
    }
  };

  const handleDeleteFeedback = async (feedbackId: number) => {
    try {
      await api.delete(`feedback/${feedbackId}`);
      setFeedbacks(feedbacks.filter(f => f.id !== feedbackId));
    } catch (error) {
      console.error('Error deleting feedback:', error);
    }
  };

  const handleTagChange = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event;
    setNewQuestion({
      ...newQuestion,
      tagIds: typeof value === 'string' ? [Number(value)] : value as number[],
    });
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'question', headerName: 'Question', flex: 1 },
    {
      field: 'modelAnswerMap',
      headerName: 'Model Answers',
      flex: 1,
      renderCell: (params) => (
        <div>
        {params.value && typeof params.value === 'object' 
          ? Object.entries(params.value).map(([key, value]) => (
              <div key={key}>{`${value}`}</div>
            ))
          : 'No model answers'
        }
      </div>
      )

    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEditQuestion(params.row)}>
            <Edit />
          </IconButton>
          <IconButton onClick={() => {
            setSelectedQuestionId(params.row.id);
            fetchFeedbacks(params.row.id);
            setOpenFeedbackDialog(true);
          }}>
            <FeedbackIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteQuestion(params.row.id)}>
            <Delete />
          </IconButton>
        </>
      ),
    },
  ];

  const handleAddModelAnswer = () => {
    const newKey = Object.keys(editingQuestion?.modelAnswerMap || newQuestion.modelAnswerMap).length + 1;
    if (editingQuestion) {
      setEditingQuestion({
        ...editingQuestion,
        modelAnswerMap: { ...editingQuestion.modelAnswerMap, [newKey]: '' }
      });
    } else {
      setNewQuestion({
        ...newQuestion,
        modelAnswerMap: { ...newQuestion.modelAnswerMap, [newKey]: '' }
      });
    }
  };

  const handleModelAnswerChange = (key: number, value: string) => {
    if (editingQuestion) {
      setEditingQuestion({
        ...editingQuestion,
        modelAnswerMap: { ...editingQuestion.modelAnswerMap, [key]: value }
      });
    } else {
      setNewQuestion({
        ...newQuestion,
        modelAnswerMap: { ...newQuestion.modelAnswerMap, [key]: value }
      });
    }
  };

  return (
    <Box sx={{ height: 800, width: '100%' }}>
      <Button variant="contained" onClick={() => setOpenAddDialog(true)} sx={{ mb: 2 }}>
        Add Question
      </Button>
      <DataGrid
        rows={questions}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 15 },
          },
        }}
        loading={loading}
        sx={{
          backgroundColor: 'white',
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#e0e0e0', // Equivalent to grey.300
            color: 'text.primary',
            fontSize: '1rem',
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-cell': {
            color: 'text.primary',
            fontSize: '0.875rem',
          },
          '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'grey.200',
          },
        }}
      />

      {/* Add/Edit Question Dialog */}
      <Dialog
        open={openAddDialog}
        onClose={() => {
          setOpenAddDialog(false);
          setEditingQuestion(null);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
          {editingQuestion ? 'Edit Question' : 'Add New Question'}
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" sx={{ mb: 1, color: 'primary.main', fontWeight: 'bold' }}>
              Question
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              label="Question Text"
              fullWidth
              multiline
              rows={3}
              value={editingQuestion ? editingQuestion.question : newQuestion.question}
              onChange={(e) => editingQuestion
                ? setEditingQuestion({ ...editingQuestion, question: e.target.value })
                : setNewQuestion({ ...newQuestion, question: e.target.value })
              }
              variant="outlined"
            />
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" sx={{ mb: 1, color: 'primary.main', fontWeight: 'bold' }}>
              Model Answers
            </Typography>
            {Object.entries(editingQuestion?.modelAnswerMap || newQuestion.modelAnswerMap).map(([key, value]) => (
              <TextField
                key={key}
                margin="dense"
                label={`Model Answer ${key}`}
                fullWidth
                value={value}
                onChange={(e) => handleModelAnswerChange(Number(key), e.target.value)}
                variant="outlined"
                sx={{ mb: 1 }}
              />
            ))}
            <Button
              onClick={handleAddModelAnswer}
              variant="outlined"
              startIcon={<Add />}
              sx={{ mt: 1 }}
            >
              Add Model Answer
            </Button>
          </Box>

          <Box>
            <Typography variant="h6" sx={{ mb: 1, color: 'primary.main', fontWeight: 'bold' }}>
              Tags
            </Typography>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select Tags</InputLabel>
              <Select
                multiple
                value={editingQuestion ? editingQuestion.tagIds : newQuestion.tagIds}
                onChange={(e: SelectChangeEvent<number[]>) => {
                  const value = e.target.value as number[];
                  editingQuestion
                    ? setEditingQuestion({ ...editingQuestion, tagIds: value })
                    : setNewQuestion({ ...newQuestion, tagIds: value });
                }}
                renderValue={(selected) => (selected as number[]).map(id => tags.find(tag => tag.id === id)?.name).join(', ')}
                label="Select Tags"
              >
                {tags.map((tag) => (
                  <MenuItem key={tag.id} value={tag.id}>
                    {tag.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2, bgcolor: 'grey.100' }}>
          <Button
            onClick={() => {
              setOpenAddDialog(false);
              setEditingQuestion(null);
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={editingQuestion ? handleUpdateQuestion : handleAddQuestion}
            variant="contained"
          >
            {editingQuestion ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Feedback Dialog */}
      <Dialog open={openFeedbackDialog} onClose={() => setOpenFeedbackDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          <Typography>
            Manage Feedbacks
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {feedbacks.map((feedback, index) => (
                <React.Fragment key={feedback.id}>
                  <Box
                    sx={{
                      mb: 2,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      background: '#f9f9f9',
                      borderRadius: '4px',
                      p: 2,
                      '&:hover': {
                        background: '#f0f0f0',
                      },
                    }}
                  >
                    <Box sx={{ flexGrow: 1, mr: 2 }}>
                      {editingFeedbackId === feedback.id ? (
                        <TextField
                          value={feedback.feedback}
                          onChange={(e) => {
                            const updatedFeedbacks = feedbacks.map(f =>
                              f.id === feedback.id ? { ...f, feedback: e.target.value } : f
                            );
                            setFeedbacks(updatedFeedbacks);
                          }}
                          fullWidth
                          variant="outlined"
                          size="small"
                        />
                      ) : (
                        <Typography>{feedback.feedback}</Typography>
                      )}
                    </Box>
                    <Box>
                      {editingFeedbackId === feedback.id ? (
                        <IconButton onClick={() => handleUpdateFeedback(feedback)} color="primary">
                          <Save />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => handleEditFeedback(feedback.id)} color="primary">
                          <Edit />
                        </IconButton>
                      )}
                      <IconButton onClick={() => handleDeleteFeedback(feedback.id)} color="error">
                        <Delete />
                      </IconButton>
                    </Box>
                  </Box>
                  {index < feedbacks.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="New Feedback"
                value={newFeedback.feedback}
                onChange={(e) => setNewFeedback({ ...newFeedback, feedback: e.target.value })}
                fullWidth
              />
              <Button
                startIcon={<Add />}
                onClick={handleAddFeedback}
                variant="contained"
                sx={{ mt: 1 }}
              >
                Feedback
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFeedbackDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default QuestionManager;